<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>
    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :action-label="actionLabel"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
      >
        <template #cell(isPlan)="{ item }">
          <div
            :class="
              item.isPlan && item.isPlan === 'สำเร็จ'
                ? 'text-success'
                : 'text-danger'
            "
          >
            {{ item.isPlan }}
          </div>
        </template>
        <template #cell(seeDetail)="{ item }">
          <div v-if="seeDetail" class="text-center">
            <a href="javascript:void(0)" @click="onSeeDetail(item)">
              <font-awesome-icon icon="file-alt" />
            </a>
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, MRank, Report } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "เพิ่ม/แก้ไข",
    },
    actionLabel: {
      type: String,
      default: "เพิ่ม/แก้ไขข้อมูล",
    },
    seeDetail: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      sortBy: "",
      sortDesc: false,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        numberOfPages = 1;

      const params = {},
        additionalParams = {};

      if (!ctx.downloadAll) {
        this.$set(params, "limit", ctx.perPage);
        this.$set(params, "offset", (ctx.currentPage - 1) * ctx.perPage);
      }

      if (ctx.downloadAll) {
        this.$set(params, "limit", 5000);
        this.$set(params, "offset", ctx.offset);
      }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "mStationId", this.params.mStationId);
      }

      if (this.params && this.params.isPlan) {
        this.$set(additionalParams, "isPlan", this.params.isPlan);
      }

      if (this.params && this.params.fiscalYearFrom) {
        this.$set(
          additionalParams,
          "fiscalYearFrom",
          this.params.fiscalYearFrom
        );
      }

      if (this.params && this.params.termFrom) {
        this.$set(additionalParams, "termFrom", this.params.termFrom);
      }

      if (this.params && this.params.fiscalYearTo) {
        this.$set(additionalParams, "fiscalYearTo", this.params.fiscalYearTo);
      }

      if (this.params && this.params.termTo) {
        this.$set(additionalParams, "termTo", this.params.termTo);
      }

      if (this.params && this.params.startDate) {
        this.$set(additionalParams, "startDate", this.params.startDate);
      }

      if (this.params && this.params.endDate) {
        this.$set(additionalParams, "endDate", this.params.endDate);
      }

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      if (this.sortBy) {
        this.$set(additionalParams, "order", this.sortBy);
        this.$set(additionalParams, "order_by", this.sortDesc ? "DESC" : "ASC");
      }

      this.isExporting = true;

      try {
        MRank.api().findAll();

        const promise = await Report.api().getTeachingProgress(
          { ...params, ...additionalParams },
          { save: false }
        );

        let {
          data: responseData = [],
          number_of_pages = 1,
        } = promise.response.data;

        this.onCountData(number_of_pages);

        data = responseData.map((record) => {
          let user_name = "",
            position_name = "-";

          if (record.user_profile) {
            const userRank = MRank.find(record.user_profile.mRankId);

            user_name = `${record.user_profile.first_name} ${record.user_profile.last_name}`;

            if (userRank) {
              user_name = `${userRank.m_rank_name} ${user_name}`;
            }

            position_name = record.user_profile.position_name;
          }

          let yearTerm = record.fiscal_year || "";

          if (record.term) {
            yearTerm += `/${record.term}`;
          }

          let classRoom = "";

          if (record.m_class) {
            classRoom += record.m_class.m_class_name;

            const roomNumber = parseInt(record.room);

            if (!isNaN(roomNumber)) {
              classRoom += `/${roomNumber}`;
            }
          }

          let isPlan = "ไม่สำเร็จ";

          if (record.isPlan && record.isPlan === "ทำแผนการสอน") {
            isPlan = "สำเร็จ";
          }

          let station = null,
            division = null,
            headquarter = null;

          if (record.m_station) {
            station = record.m_station.m_station_name;

            if (record.m_station.m_division) {
              division = record.m_station.m_division.m_division_name;

              if (record.m_station.m_division.m_headquarter) {
                headquarter =
                  record.m_station.m_division.m_headquarter.m_headquarters_name;
              }
            }
          }

          return {
            ...record,
            plan_student: record.plan_student || 0,
            actual_student: record.actual_student || 0,
            // teaching_plan_count: record.teaching_plan_count || 0,
            teaching_plan_count: "13",
            room: record.room || "-",
            fiscal_year: record.fiscal_year || "-",
            class: record.m_class ? record.m_class.m_class_name : null,
            school: record.m_school ? record.m_school.m_school_name : null,
            classRoom,
            yearTerm,
            station,
            division,
            headquarter,
            amphur:
              record.m_school && record.m_school.m_amphur
                ? record.m_school.m_amphur.m_amphur_name
                : null,
            province:
              record.m_school && record.m_school.m_province
                ? record.m_school.m_province.m_province_name
                : null,
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatNumeric(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatNumeric(record.createdAt)
              : "-",
            isPlan,
            user_name,
            position_name,
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        this.$toast.error("ไม่สามารถดึงข้อมูลรายงานได้ กรุณาลองใหม่ภายหลัง");
      } finally {
        this.isExporting = false;
      }

      if (!ctx.downloadAll) {
        this.numberOfPages = numberOfPages;
      }

      return data;
    },

    onSeeDetail(item) {
      this.$emit("seeDetail", item);
    },

    onCountData(count) {
      this.$emit("countData", count);
    },

    editTeaching(item) {
      this.$emit("edit", item);
    },

    deleteTeaching(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
